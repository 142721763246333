import styled from "styled-components"
import { LG } from "../../../utils/constants"

import { MainH2, MainParagraph } from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  @media (min-width: ${LG}) {
    padding: 70px 0;
    max-width: 606px;
    text-align: center;
    margin: 0 auto;
  }

  @media (max-width: ${LG}) {
    margin-top: 120px;
    padding: 0 30px;
  }
`

export const Title = styled(MainH2)`
  max-width: unset;
  padding-bottom: 20px;
`

export const Text = styled(MainParagraph)`
  font-weight: 500;

  color: ${props => props.theme.navy};

  @media (max-width: ${LG}) {
    padding: 5px 0 30px 0;
  }
`

export const BlobsWrap = styled.div`
  overflow: hidden;
`
