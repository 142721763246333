import React from "react"
import { useStaticQuery } from "gatsby"
import { DefaultWrapper } from "../../../utils/GlobalComponents"
import { Wrapper, TitleSeparator } from "./style"
import Blob from "../../common/Blob/Blob"

import Investigators from "../../Team/Members/Investigators/Investigators"
import Administrative from "../../Team/Members/Administrative/Administrative"
import Researchers from "../../Team/Members/Researchers/Researchers"
import { useMediaQuery } from "react-responsive"

const Members = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })

  const isNotebookOrIpad = useMediaQuery({
    query: "(max-device-width: 1199px)",
  })
  const {
    allWordpressPage: { edges },
  } = useStaticQuery(graphql`
    query CategoryTeam {
      allWordpressPage(filter: { wordpress_id: { eq: 329 } }) {
        edges {
          node {
            acf {
              team_page_category_1
              team_page_category_2
            }
          }
        }
      }
    }
  `)
  return (
    <Wrapper>
      <Blob
        toTop={
          isTabletOrMobileDevice ? "17%" : isNotebookOrIpad ? "10%" : "10%"
        }
        toRight={
          isTabletOrMobileDevice ? "13%" : isNotebookOrIpad ? "23%" : "10%"
        }
        customWidth={
          isTabletOrMobileDevice ? "0" : isNotebookOrIpad ? "18vw" : "30vw"
        }
        customHeight={isNotebookOrIpad ? "50vw" : "40.1vw"}
        delay={"400"}
      />
      <Blob
        toTop={
          isTabletOrMobileDevice ? "17%" : isNotebookOrIpad ? "10%" : "10%"
        }
        toRight={
          isTabletOrMobileDevice ? "13%" : isNotebookOrIpad ? "30%" : "21%"
        }
        customWidth={
          isTabletOrMobileDevice ? "0" : isNotebookOrIpad ? "6vw" : "8vw"
        }
        customHeight={isNotebookOrIpad ? "50vw" : "40.1vw"}
        delay={"400"}
      />

      {edges.map(({ node }, i) => {
        return (
          <DefaultWrapper key={i}>
            <Investigators />
            <Administrative title={node.acf.team_page_category_2}/>
            <Researchers title={node.acf.team_page_category_2}/>
          </DefaultWrapper>
        )
      })}
    </Wrapper>
  )
}

export default Members
