import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/Team/Hero/Hero"
import Members from "../components/Team/Members/Members"

const TeamPage = () => {
  return (
    <Layout>
      <SEO title="Team" />
      <Hero />
      <Members />
    </Layout>
  )
}

export default TeamPage
