import React, { Fragment } from "react"
import { DefaultWrapper } from "../../../utils/GlobalComponents"
import { Wrapper, Title, Text, BlobsWrap } from "./style"

const Hero = () => {
  return (
    <DefaultWrapper>
      <BlobsWrap>
        <Fragment>
          <Wrapper data-aos="fade-up" data-aos-duration="1000">
            <Title>The LymeMIND Team</Title>
            <Text
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="600"
            >
              Based at the Icahn School of Medicine at Mount Sinai, the team is
              made up of diverse experts in the areas of computational biology,
              epidemiology, software engineering systems biology, and Lyme
              disease research.
            </Text>
          </Wrapper>
        </Fragment>
      </BlobsWrap>
    </DefaultWrapper>
  )
}

export default Hero
