import React, { useState } from "react"
import { useStaticQuery } from "gatsby"

import { DefaultWrapper } from "../../../../utils/GlobalComponents"
import { Wrapper } from "./style"
import BioCard from "../../BioCard/BioCard"
import BioModal from "../../BioModal/BioModal"
import { TitleSeparator } from "../style"

const Researchers = ({title=""}) => {
  const [modalShow, setModalShow] = useState({
    visible: false,
    info: null,
  })

  const {
    allWordpressWpTeamMember: { edges },
  } = useStaticQuery(graphql`
    query ResearchersQuery {
      allWordpressWpTeamMember(
        filter: { acf: { category: { eq: "Researcher/Technical" } } }
      ) {
        edges {
          node {
            acf {
              category
              biography
              position
              profile_picture {
                source_url
              }
            }
            title
            id
          }
        }
      }
    }
  `)
  if (edges.length === 0) return null
  return (
    <DefaultWrapper>
      <TitleSeparator>{title}</TitleSeparator>
      <BioModal
        animation={"fade"}
        visible={modalShow.visible}
        info={modalShow.info}
        onClose={() =>
          setModalShow({
            visible: false,
            info: null,
          })
        }
      />

      <Wrapper>
        {edges.map(({ node }, i) => (
          <BioCard
            key={node.id}
            data={node}
            cardDelay={i % 2 === 0 ? "200" : "400"}
            openModal={() =>
              setModalShow({
                visible: true,
                info: node,
              })
            }
          />
        ))}
      </Wrapper>
    </DefaultWrapper>
  )
}

export default Researchers
