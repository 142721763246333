import React, { useEffect, useState } from "react"

import Rodal from "rodal"
import "rodal/lib/rodal.css"
import { DefaultWrapper } from "../../../utils/GlobalComponents"
import { Wrapper, Image, Title, Subtitle, Text, Grid } from "./style"

const BioModal = ({ onClose, visible, info, animation }) => {
  const [positionScroll, setPositionScroll] = useState(null)
  useEffect(() => {
    if (visible) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = "auto"
    }

    if (visible) {
      setPositionScroll(window.pageYOffset)
      setTimeout(() => {
        document.documentElement.style.overflowY = "scroll"
        document.body.style.top = `-${positionScroll}px`
        document.body.style.right = `0`
      }, 200)

      setTimeout(() => {
        document.body.style.position = "fixed"
      }, 400)
    } else {
      setTimeout(() => {
        document.body.style.position = ""

        window.scrollTo(0, positionScroll)
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, positionScroll])

  return (
    <DefaultWrapper>
      {info && (
        <Wrapper>
          <Rodal
            enterAnimation={"slideUp"}
            leaveAnimation={"fade"}
            duration={300}
            visible={visible}
            onClose={onClose}
            info={info}
          >
            <Grid>
              <div>
                <Image
                  bg={info.acf.profile_picture.source_url}
                  alt={info.acf.profile_picture.alt_text}
                />
              </div>
              <div>
                <Title>{info.title}</Title>
                <Subtitle>{info.acf.position}</Subtitle>
                <Text
                  dangerouslySetInnerHTML={{ __html: info.acf.biography }}
                />
              </div>
            </Grid>
          </Rodal>
        </Wrapper>
      )}
    </DefaultWrapper>
  )
}

export default BioModal
