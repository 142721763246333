import styled from "styled-components"

import { MainParagraph } from "../../../utils/GlobalComponents"
import { LG } from "../../../utils/constants"

export const Wrapper = styled.div`
  .rodal-mask {
    position: absolute;
    background: #faf7f5;
    opacity: 0.8;
  }
  .rodal-dialog {
    background-color: white;
    max-height: none;
    max-width: 936px;
    height: 97% !important;
    padding: 66px 66px 30px;
    box-shadow: 4px 20px 60px rgba(243, 139, 151, 0.5);
    margin: 10px auto 20px;
    overflow-y: scroll;

    border: 6px solid white;

    @media (max-width: ${LG}) {
      height: 100% !important;
      margin: 0;
      padding: 60px 40px 40px 40px;
    }
  }

  .rodal-dialog::-webkit-scrollbar {
    width: 8px;
  }
  .rodal-dialog {
    scrollbar-width: thin;
    scrollbar-color: linear-gradient(0deg, #f38b97 20.01%, #f4904d 100%);
  }
  .rodal-dialog::-webkit-scrollbar-thumb {
    background: rgba(176, 201, 203, 0.22);
    border-radius: 20px;
  }

  .rodal-close {
    background-color: white;
    opacity: 1;
    border-radius: 50%;
    box-shadow: 0px 4px 9px rgba(0, 43, 52, 0.12);
    padding: 40px;
    transition: ${props => props.theme.defaultTransition()};
    top: 20px;
    right: 20px;

    &:after,
    &:before {
      width: 40%;

      background-color: ${props => props.theme.lightGreen};
      margin: 0 auto;
      opacity: 1;
      left: 30%;
    }

    &:hover {
      box-shadow: 0px 4px 9px rgba(0, 43, 52, 0.3);
    }

    @media (max-width: ${LG}) {
      top: 25px;
      right: 10px;
      padding: 40px;
    }
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 1fr;

  a span {
    color: #00b08a;
    transition: ${props => props.theme.defaultTransition()};

    &:hover {
      opacity: 0.6;
    }
  }

  > div {
    &:last-child {
      max-width: 500px;
      margin-left: 80px;
    }
  }

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;

    > div {
      &:last-child {
        max-width: unset;
        margin-left: inherit;
      }
    }

    > div {
      &:first-child {
        height: 264px;
      }
    }
  }
`

export const Image = styled.div`
  max-width: 202px;
  max-height: 264px;
  height: 100%;
  width: 100%;
  background: url(${props => props.bg}) no-repeat center center / cover;
  margin: 20px 0 0 15px;

  @media (max-width: ${LG}) {
    max-width: unset;
    margin: 0;
  }
`

export const Title = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: ${props => props.theme.navy};

  @media (max-width: ${LG}) {
    line-height: 36px;
    margin-top: 50px;
    margin-bottom: 10px;
  }
`
export const Subtitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  background: ${props => props.theme.orangeGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;

  @media (min-width: ${LG}) {
    max-width: 400px;
  }

  &:after {
    content: "";
    display: block;
    width: 64px;
    height: 4px;
    background: ${props => props.theme.orangeGradient};
    margin-top: 4px;
  }

  @media (max-width: ${LG}) {
    margin-bottom: 40px;
    &:after {
      margin-top: 12px;
    }
  }
`

export const Text = styled(MainParagraph)`
  strong {
    font-weight: 500;
  }
`
