import React from "react"
import {
  Wrapper,
  BioTitle,
  BioSubtitle,
  BioButton,
  TextContent,
  ProfilePic,
} from "./style"

import PlusIcon from "../../../images/plus-icon.svg"

const BioCard = ({ data, openModal, cardDelay }) => {
  /*   const {
    allWordpressPage: { edges },
  } = useStaticQuery(graphql`
    query MyQuery {
      allWordpressPage(filter: { wordpress_id: { eq: 329 } }) {
        edges {
          node {
            id
            acf {
              team_page_title
              team_page_text
            }

          }
        }
      }
    }
  `) */

  return (
    <Wrapper
      onClick={e =>
        openModal({
          visible: true,
          info: data,
        })
      }
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-delay={cardDelay}
      data-aos-once={true}
    >
      <div className="top">
        <ProfilePic bg={data.acf.profile_picture.source_url}>
          <div></div>
        </ProfilePic>
        <TextContent>
          <BioTitle>{data.title}</BioTitle>
          <BioSubtitle>{data.acf.position}</BioSubtitle>
        </TextContent>
      </div>
      <BioButton>
        <div className="wrapper">
          <img src={PlusIcon} alt="open bio" />
        </div>
      </BioButton>
    </Wrapper>
  )
}

export default BioCard
