import styled from "styled-components"
import { MainParagraph } from "../../../utils/GlobalComponents"
import { LG } from "../../../utils/constants"

export const TextContent = styled.div`
  padding: 25px 25px 112px 30px;
  height: 200px;
`

export const ProfilePic = styled.div`
  background: url(${props => props.bg}) no-repeat center center/ cover;
  position: relative;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transform: scale(1);
  transition: ${props => props.theme.defaultTransition()};

  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.theme.orangeGradient};
    opacity: 0.4;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    transition: ${props => props.theme.defaultTransition()};
  }

  @media (max-width: ${LG}) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

export const BioTitle = styled(MainParagraph)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.navy};
  letter-spacing: 0.01em;

  margin-bottom: 0;
`

export const BioSubtitle = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  background: ${props => props.theme.orangeGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const BioButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  width: 161px;

  .wrapper {
    margin-left: auto;
    width: 48px;
    height: 48px;
    background: linear-gradient(97.08deg, #f38b97 20.01%, #f4904d 75.82%);
    padding-right: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    transition: ${props => props.theme.defaultTransition()};
    border-bottom-right-radius: 4px;
  }

  cursor: pointer;
  opacity: 1;
  position: absolute;
  right: 0;

  img {
    width: 16px;
    height: 16px;
  }

  &:before {
    content: "VIEW BIO";
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.09em;
    color: white;

    position: absolute;
    bottom: 16px;
    right: 70px;

    opacity: 0;
    transform: translateX(20px);
    transition: 0.25s ease all;
  }
`

export const Wrapper = styled.div`

  background-color: white;

  box-shadow: 0 13px 27px -5px rgba(0, 43, 52, 0.08), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);

  border-radius: 4px;
  position: relative;
  cursor: pointer;
  transition: ${props => props.theme.defaultTransition()};
  overflow:hidden;

  .top {
    display: grid;
    grid-template-columns: 144px 1fr;
  }

  &:hover {
    box-shadow: 0 30px 60px -12px rgba(243, 139, 151, 0.5), 0 18px 36px -18px rgba(243, 139, 151, 0.3), 0 -12px 36px -8px rgba(243, 139, 151, 0.025);

    ${BioButton} {
      transition: ${props => props.theme.defaultTransition()};
      .wrapper {
        width: 161px;
      }

      &:before {
        opacity: 1;
        transform: translateX(0)
      }
    }

    ${ProfilePic} {
      transform:scale(1.1);
      > div {
        transition: ${props => props.theme.defaultTransition()};
        opacity: 0;
      }
    }
  }



  @media (max-width: ${LG}) {
    grid-template-columns: 1.1fr 0.9fr;

    ${BioButton}, &:hover ${BioButton}{
      opacity: 1;
      right: 0;
      position: relative;
      width: 100%;
      bottom: auto;
      top: auto;
      .wrapper {
        width: 100%;
        border-bottom-left-radius: 4px;
      }
      &:before {
        opacity: 1;
        transform: translateX(0);
        width: 100%;
        width: auto;
        right: 48px;
      }
    }
  }
`
