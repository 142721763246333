import React, { useState } from "react"
import { useStaticQuery } from "gatsby"

import { DefaultWrapper } from "../../../../utils/GlobalComponents"
import { Wrapper } from "./style"
import BioCard from "../../BioCard/BioCard"
import BioModal from "../../BioModal/BioModal"
import { useMediaQuery } from "react-responsive"
import Blob from "../../../common/Blob/Blob"
import { TitleSeparator } from "../style"

const Administrative = ({title=""}) => {
  const [modalShow, setModalShow] = useState({
    visible: false,
    info: null,
  })

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })

  const isNotebookOrIpad = useMediaQuery({
    query: "(max-device-width: 1199px)",
  })

  const {
    allWordpressWpTeamMember: { edges },
  } = useStaticQuery(graphql`
    query Administrative {
      allWordpressWpTeamMember(
        filter: {
          categories: { elemMatch: { slug: { eq: "administrative" } } }
        }
      ) {
        edges {
          node {
            acf {
              biography
              category
              position
              profile_picture {
                source_url
              }
            }
            title
            id
          }
        }
      }
    }
  `)
  if (edges.length === 0) return null
  return (
    <DefaultWrapper>
      <TitleSeparator>{title}</TitleSeparator>
      <BioModal
        animation={"fade"}
        visible={modalShow.visible}
        info={modalShow.info}
        onClose={() =>
          setModalShow({
            visible: false,
            info: null,
          })
        }
      />

      <Wrapper>
        <Blob
          toTop={
            isTabletOrMobileDevice ? "17%" : isNotebookOrIpad ? "15%" : "10%"
          }
          toLeft={
            isTabletOrMobileDevice ? "13%" : isNotebookOrIpad ? "-25vw" : "-25vw"
          }
          customWidth={
            isTabletOrMobileDevice ? "0" : isNotebookOrIpad ? "15vw" : "15vw"
          }
          customHeight={isNotebookOrIpad ? "50vw" : "40.1vw"}
          delay={"400"}
        />
        {edges.map(({ node }, i) => (
          <BioCard
            key={node.id}
            data={node}
            cardDelay={i % 2 === 0 ? "200" : "400"}
            openModal={() =>
              setModalShow({
                visible: true,
                info: node,
              })
            }
          />
        ))}
      </Wrapper>
    </DefaultWrapper>
  )
}

export default Administrative
