import styled from "styled-components"
import { LG } from "../../../utils/constants"
import { MainParagraph } from "../../../utils/GlobalComponents"

export const Wrapper = styled.div`
  background-color: ${props => props.theme.neutralSilver};
  padding: 120px 0;
  overflow: hidden;


  @media (max-width: ${LG}) {
    padding: 60px 20px 0 20px;
  }
`

export const TitleSeparator = styled(MainParagraph)`
  color: ${props => props.theme.neutralDark};
  font-weight: 500;
  max-width: 400px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 120px 0 50px 0;

  &:after {
    content: "";
    display: block;
    width: 50px;
    height: 4px;
    background: ${props => props.theme.orangeGradient};
    margin-top: 2px;
    margin: 30px auto;
  }

  @media (max-width: ${LG}) {
    max-width: auto;
  }
`
