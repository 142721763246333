import styled from "styled-components"
import { LG } from "../../../../utils/constants"

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px 25px;
  max-width: 941px;
  margin: 0 auto;

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
    grid-gap: 68px 25px;
  }
`
